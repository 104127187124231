import React from "react"
import { Link,graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Feature from "../components/feature"
import YesNo from "../components/yesno"
import FlexLayout from "../components/flex-layout"

import './index.less'

const HomePage = ({data}) => {

  return (

  <Layout title="Home" keywords={[`rail api`, `train ticket`, `api`]}>
    <content>

      <table>
        <tbody>
          <tr>
            <td>
              <Link to="/smartix/" className="printLink"><Img fixed={data.smartixLogo.childImageSharp.fixed} /></Link>
              <h1 className="tagline">
                unconventional rail reservation and fulfilment
                <span>effortless &#183; economical &#183; powerful</span>
              </h1>
            </td>
            <td width="750">
              <Img fixed={data.frogImage.childImageSharp.fixed} alt="frog" class="mainFrog"/>
            </td>
          </tr>
        </tbody>
      </table>

    </content>

    <Spacer>
      <p>
        <Link to="/smartix/" className="printLink">smartix</Link> is the new UK rail ticket fulfilment 
        system that is as simple to use as a take away menu. 
      </p>

      <p>
        Why should buying a rail fare be any more complex than ordering number 21, 34 and 56?
      </p>
    </Spacer>
    
    <content>
      <h2><a href="/smartix/" className="printLink">smartix</a> : A UK Rail Ticket Fulfilment API</h2>

      <p>You are a major travel agent. You recognise a market for bundling rail tickets with package holidays.</p>

      <p>You decide to add trains to your portfolio. What are your options?</p>

      <FlexLayout>
        <YesNo mode="no" title="Build your own TIS">
          <p>Too complicated - prior expert knowledge required</p>
          <p>Too expensive and time consuming</p>
        </YesNo>

        <YesNo mode="no" title="Buy a TIS">
          <p>None available for sale</p>
          <p>Too expensive for a bespoke commission</p>
        </YesNo>

        <YesNo mode="no" title="Use a white-label">
          <p>You need trains integrated into your products</p>
          <p>Standalone sales are not desirable</p>
        </YesNo>

        <YesNo mode="no" title="Use a conventional TIS">
          <p>Expensive and inflexible</p>
          <p>All very similar, same problems</p>
          <p>no unique selling points to drive sales</p>
        </YesNo>

        <Link to="/smartix/">
          <YesNo mode="yes" title="Use an unconventional TIS">
            <p>Easier, quicker and cheaper integration</p>
            <p>Low transaction fee</p>
            <p>Unique features that will drive customer loyalty</p>
          </YesNo>
        </Link>
      </FlexLayout>
    </content>

    <Spacer>
      <Link to="/smartix/" className="printLink"><Img fixed={data.smartixLogo.childImageSharp.fixed} /></Link>

      <p>Which brings you to <Link to="/smartix/" className="printLink">smartix</Link> the unconventional TIS...</p>

      <p><Link to="/smartix/" className="printLink">smartix</Link> provides the features you need in an easy to consume API at a price that makes economic sense</p>
    </Spacer>

    <content>

      <FlexLayout>
        <Feature image={data.effortlessImage.childImageSharp.fixed.src} title="Effortless" link="/smartix/" top="120">
          <p>Powerful features can often come hand in hand with complexity</p>
          <p><Link to="/smartix/" className="printLink">smartix's</Link> incredibly simple API hides the power</p>
        </Feature>

        <Feature image={data.economicalImage.childImageSharp.fixed.src} title="Economical" link="/smartix/">
          <p>You keep 100% of the commission</p>
          <p>A small transaction fee covers our costs</p>
        </Feature>

        <Feature image={data.powerfulImage.childImageSharp.fixed.src} title="Powerful" link="/smartix/" top="120">
           <p><Link to="/smartix/" className="printLink">smartix</Link> does more</p>
           <p>So you need to do less</p>
           <p>Focus on sales, not ticket data</p>
        </Feature>

      </FlexLayout>

    </content>

  </Layout>
)}

export const query = graphql`
  query {
    frogImage: file(relativePath: {eq: "frog2.png"}) {
      childImageSharp {
        fixed(width: 650) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    smartixLogo: file(relativePath: {eq: "smartix-blue.png"}) {
      childImageSharp {
        fixed(width: 122) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    effortlessImage: file(relativePath: {eq: "features/effortless.png"}) {
      childImageSharp {
        fixed(width: 198) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    economicalImage: file(relativePath: {eq: "features/economical.png"}) {
      childImageSharp {
        fixed(width: 198) {
          ...GatsbyImageSharpFixed
        }
      }
    } 
    powerfulImage: file(relativePath: {eq: "features/powerful.png"}) {
      childImageSharp {
        fixed(width: 198) {
          ...GatsbyImageSharpFixed
        }
      }
    } 
  } 
`

export default HomePage