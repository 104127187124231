import React from "react"
import { StaticQuery,graphql } from 'gatsby'

import './yesno.less'

const YesNo = (props) => (

  <StaticQuery
    query={graphql`
      query {
        yes: file(relativePath: {eq: "tick.png"}) {
          childImageSharp {
            fixed(width: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        no: file(relativePath: {eq: "cross.png"}) {
          childImageSharp {
            fixed(width: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}

    render={data =>
      <div className="yesno">
        <h4>{props.title}</h4>
        <img src={data[props.mode].childImageSharp.fixed.src} alt={props.mode}/>
        <div className={props.mode}>{props.children}</div>
      </div>
    }
  />
)

export default YesNo
