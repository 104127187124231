import React from "react"
import { Link, navigate } from 'gatsby'

import './feature.less'

const Feature = (props) => (

  <div className="featureitem" style={{marginTop:(props.top ? props.top : 0) + "px"}} onClick={event => {navigate(props.link)}}>
    <img src={props.image} alt={props.title}/>
    <h4>{props.title}</h4>
    <div>{props.children}</div>
    {props.link &&
      <Link to={props.link} className="moreLink">read more...</Link>
    }
  </div>
)

export default Feature
